<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <div
        class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
        :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
      >
        <button
          v-bind="attrs"
          v-on="on"
          class="btn btn--export-filter import-btn-guide"
        >
          <span class="svg-icon">
            <v-icon size="18">mdi-cloud-upload</v-icon>
          </span>
          Import
        </button>
      </div>
    </template>
    <v-list>
      <v-list-item v-for="(data, index) in types" :key="index">
        <v-list-item-title
          ><v-btn block @click="actionFunction(data.type)">{{
            data.name
          }}</v-btn></v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
// import Swal from "sweetalert2";
export default {
  name: "UploadSelect",
  props: ["types", "actionFunction"],

  methods: {},
  computed: {},
};
</script>
